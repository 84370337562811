<script lang="ts">
  import { cva } from 'class-variance-authority';
  import type { ResourceType } from '$lib/contentful/models/resource';

  export let type: ResourceType;
  export let entityId: string;
  export let fieldId: string;

  const tagStyles = cva(
    [
      'rounded-md',
      'w-fit',
      'py-2',
      'px-4',
      'font-mono',
      'uppercase',
      'text-sm',
      'text-space-black',
    ],
    {
      variants: {
        type: {
          'Blog Post': 'bg-teal',
          Webinar: 'bg-yellow-700',
          External: 'bg-mint',
          'Case Study': 'bg-bubble-gum',
          'Replay Talk': 'bg-ultraviolet',
          Logo: 'bg-none',
          'Technical Guide': 'bg-deep-blue text-white',
          Whitepaper: 'bg-deep-blue text-white',
        },
      },
    },
  );
</script>

<div
  data-contentful-entry-id={entityId}
  data-contentful-field-id={fieldId}
  class="tag {tagStyles({ type })}"
>
  <slot />
</div>
