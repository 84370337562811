<script lang="ts" context="module">
  export const ResourcePaths: Record<ResourceType, string> = {
    'Blog Post': '/blog/:slug',
    'Case Study': '/resources/case-studies/:slug',
    'Replay Talk': '/resources/on-demand/:slug',
    Webinar: '/resources/on-demand/:slug',
    Whitepaper: '/resources/longform/:slug',
    'Technical Guide': '/resources/longform/:slug',
    Logo: '',
  };
</script>

<script lang="ts">
  import type { Resource, ResourceType } from '$lib/contentful/models/resource';

  export let resource: Resource;

  $: ({ link, type, slug, entityId } = resource);

  $: href = slug ? ResourcePaths[type].replace(':slug', slug) : link;
</script>

{#if slug}
  <a
    data-contentful-field-id="slug"
    data-contentful-entry-id={entityId}
    class="resource-link {$$props.class}"
    {href}><slot /></a
  >
{:else if link}
  <a
    data-contentful-field-id="link"
    data-contentful-entry-id={entityId}
    class="resource-link {$$props.class}"
    target="_blank"
    {href}><slot /></a
  >
{:else}
  <slot />
{/if}

<style lang="postcss">
  .resource-link {
    @apply flex font-mono text-mint cursor-pointer;
  }

  :global(.resource-link .text) {
    @apply text-white;
  }
</style>
